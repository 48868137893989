<!-- OrdersTable.vue -->
<template>
  <table v-if="orders.length > 0">
    <colgroup>

      <!-- 根据列数调整 -->
    </colgroup>
    <thead>
    <tr class="order-header">
      <th>序号</th>
      <th>
        <div>WalkerID</div>
        <hr>
        <div>KlookID</div>

      </th>
      <th>下单时间</th>
      <th>使用时间</th>
      <th>机场</th>
      <th>接送</th>
      <th>人数/车型</th>
      <th>状态</th>
      <th>附加服务</th>
<!--      <th>特殊需求</th>-->
      <th>备注</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(order, index) in orders" :key="order.敖途订单编号">
      <td>{{ index + 1 }}</td>
      <td>
        <div>{{ order.敖途订单编号 }}</div>
        <hr>
        <div>{{ order.klook订单编号 }}</div>
      </td>

      <td>{{ formatDateTime(order.订票时间) }}</td>
      <td>{{ formatDateTime(order.使用时间) }}</td>
      <td>{{ order.机场 }}</td>
      <td>{{ order.接送类型 }}</td>
      <td>{{ order.客人数 }}/{{ order.车型 }}</td>
      <td>{{ order.订单状态 }}</td>
      <td>{{ order.附加服务 }}</td>
<!--      <td>{{ order.特殊需求 }}</td>-->
      <td v-if="!order.editing">{{ order.备注 }}</td>
      <td v-else><input type="text" v-model="order.editedRemark" /></td>
      <td>
        <button v-if="!order.editing" @click="enableEditing(order)">编辑</button>
        <template v-if="order.editing">
          <button @click="submitRemark(order)">提交</button>
          <button @click="cancelEditing(order)">取消</button>
        </template>
      </td>
    </tr>
    </tbody>
  </table>
</template>


<script setup>
import dayjs from 'dayjs';
import 'dayjs/locale/ja'; // 导入日语语言包
import utc from 'dayjs/plugin/utc'; // 处理UTC时间
import timezone from 'dayjs/plugin/timezone'; // 处理时区

dayjs.extend(utc);
dayjs.extend(timezone);

defineProps({
  orders: Array,
});

const emit = defineEmits({
  remark: String,
})

const enableEditing = (order) => {
  order.editing = true
  order.editedRemark = order.备注
}

const cancelEditing = (order) => {
  order.editing = false
}

const submitRemark = async (order) => {
  emit("remark", order.敖途订单编号, order.editedRemark, order.接送类型);
  cancelEditing(order)
  // 更新订单对象中的备注属性
}

const formatDateTime = (dateTimeStr) => {
  return dayjs.utc(dateTimeStr).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm');
};
</script>


<style scoped>
table {
  width: 100%; /* 推荐全宽，或者根据实际需要调整 */
  border-collapse: collapse;
  margin-top: 20px;
}


td, th {
  border: 1px solid #ccc; /* 给表格单元格添加边框 */
  text-align: left; /* 文本左对齐 */
  padding: 8px; /* 添加一些内边距 */
}

.order-header {
  background-color: #f9f9f9; /* 给表头添加背景色 */
}

</style>