<template>
  <div class="login-form">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="username">Username:</label>
        <input id="username" type="text" v-model="username" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input id="password" type="password" v-model="password" required>
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter();
const username = ref('');
const password = ref('');

const handleLogin = async () => {
  try {
    const response = await axios.post('/api/login', {
      username: username.value,
      password: password.value,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log(response.data.status);
    if (response.data.status === 0) {
      // 处理响应数据，例如保存token，跳转到另一个路由等
      localStorage.setItem('token', response.data.token);
      // const now = new Date();
      // const expires = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000); // 设置过期时间为7天后
      document.cookie = `token=${response.data.token}; path=/;`;
      router.push({ name: 'OrderQuery' });
    }

  } catch (error) {
    console.error(error);
    // 处理错误，例如显示错误消息
  }
};
</script>

<style scoped>
.login-form {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: lightyellow;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
