<template>
  <div>
    <h1>订单搜索</h1>
    <div>
      <!-- 下单时间选择 -->
      <div>
        <h3>按下单时间搜索</h3>
        <label for="orderStart">开始日期:</label>
        <input type="date" v-model="orderStart">
        <label for="orderEnd">结束日期:</label>
        <input type="date" v-model="orderEnd">
      </div>

      <!-- 用车时间选择 -->
      <div>
        <h3>按使用时间搜索</h3>
        <label for="useStart">开始日期:</label>
        <input type="date" v-model="useStart">
        <label for="useEnd">结束日期:</label>
        <input type="date" v-model="useEnd">
      </div>

      <div class="select-container">
        <div>
          <h4>选择城市</h4>
          <select v-model="selectedCity">
            <option disabled value="">请选择城市</option>
            <option value="Seoul">首尔</option>
            <option value="Jeju">济州</option>
            <option value="Busan">釜山</option>
            <!-- 更多城市选项 -->
          </select>
        </div>

        <!-- 选择机场 -->
        <div v-if="selectedCity === 'Seoul'">
          <h4>选择机场</h4>
          <select v-model="selectedAirport">
            <option disabled value="">请选择机场</option>
            <option value="Incheon">仁川</option>
            <option value="Gimpo">金浦</option>
            <option value="IncheonGimpo">仁川&金浦</option>
            <!-- 更多机场选项 -->
          </select>
        </div>
      </div>
    </div>
    <div>
      <h4>按敖途单号查询</h4>
      <input type="text" v-model="walkerID">


      <h4>按三方单号查询</h4>
      <input type="text" v-model="thirdPartID">

    </div>

    <button @click="searchOrders" :disabled="!isDateSelected">搜索订单</button>

    <div class="filter-buttons">
      <br>
      <button @click="() => setCurrentFilter('all')" :disabled="!isSearchPerformed">全部订单</button>
      <button @click="() => setCurrentFilter('confirmed')" :disabled="!isSearchPerformed">已确认</button>
      <button @click="() => setCurrentFilter('cancelled')" :disabled="!isSearchPerformed">已取消</button>
    </div>

    <!-- 结果展示 -->
    <div class="tables-container">
      <div class="table-container">
        <!-- 使用OrdersTable组件显示“送机”类型的订单 -->
        <OrdersTable :orders="orders_drop" @remark="handleRemark" title="送机"/>
      </div>
      <div class="table-container">
        <!-- 使用OrdersTable组件显示“接机”类型的订单 -->
        <OrdersTable :orders="orders_pick" @remark="handleRemark" title="接机"/>
      </div>
    </div>

  </div>
</template>


<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';
// 引入OrdersTable组件
import OrdersTable from '@/components/OrdersTable.vue';

const orderStart = ref('');
const orderEnd = ref('');
const useStart = ref('');
const useEnd = ref('');
const walkerID = ref('')
const thirdPartID = ref('')

// 计算属性，判断是否至少选择了一个日期
const isDateSelected = computed(() => {
  return orderStart.value || orderEnd.value || useStart.value || useEnd.value || walkerID.value || thirdPartID.value;
});

// 定义城市和机场选择的响应式变量
const selectedCity = ref('');
const selectedAirport = ref('');
const orders_pick = ref([]);
const orders_drop = ref([]);

// 定义响应式变量
const currentFilter = ref('all'); // 默认筛选条件为“全部订单”
const isSearchPerformed = ref(false); // 用于追踪是否执行过搜索操作

const fetchOrders = async (type, orderStatus) => {
  if (typeof orderStatus !== 'undefined') {
    if (orderStatus === 'all'){
      orderStatus = ['已确认', '已取消'];
    } else if (orderStatus === 'confirmed') {
      orderStatus = ['已确认']
    } else if (orderStatus === 'cancelled') {
      orderStatus = ['已取消']
    } else {
      orderStatus = ['已确认', '已取消']
    }
  } else {
    orderStatus = ['已确认', '已取消']
  }

  let airport

  if (selectedCity.value === '') {
    airport = ['仁川', '金浦', '济州', '釜山']
  } else if (selectedCity.value === 'Seoul') {
    if (selectedAirport.value === '' || selectedAirport.value === 'IncheonGimpo') {
      airport = ['仁川', '金浦']
    } else if (selectedAirport.value === 'Incheon') {
      airport = ['仁川']
    } else if (selectedAirport.value === 'Gimpo') {
      airport = ['金浦']
    }
  } else if (selectedCity.value === 'Jeju') {
    airport = ['济州']
  } else {
    airport = ['釜山']
  }
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get('/my/orders', {
      params: {
        orderStart: orderStart.value,
        orderEnd: orderEnd.value,
        useStart: useStart.value,
        useEnd: useEnd.value,
        type: type,
        airport,
        orderStatus,
        walkerID: walkerID.value,
        thirdPartID: thirdPartID.value
      },
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    return []; // 返回空数组以避免更多错误
  }
};

const searchOrders = async (filter='all') => {
  // 执行搜索操作的逻辑
  isSearchPerformed.value = true; // 搜索操作执行后，允许使用筛选按钮
  orders_pick.value = await fetchOrders("pick", filter);
  orders_drop.value = await fetchOrders("drop", filter);
};

const setCurrentFilter = (filter) => {
  currentFilter.value = filter;
  searchOrders(filter); // 立即根据新的筛选条件搜索订单
};

const handleRemark = async (id, remark, type) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post('/my/update-mark', {
      id: id,
      newValue: remark
    }, {
      headers: {
        Authorization: `${token}`
      }
    });
    if (response.data.status === 0) {
      // 如果更新成功，执行状态更改操作
      // editingStatus.value = true;

      if (type === '送'){
        const index = orders_drop.value.findIndex(item => item.敖途订单编号 === id);
        orders_drop.value[index]['备注'] = remark
      } else {
        const index = orders_pick.value.findIndex(item => item.敖途订单编号 === id);
        orders_pick.value[index]['备注'] = remark
      }

    } else {
      // 如果更新失败，给出相应提示
      alert(response.data.message);
    }

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    return []; // 返回空数组以避免更多错误
  }
};

</script>


<style scoped>
/* CSS styles remain the same */
body {
  font-family: Arial, sans-serif;
}

div {
  margin-bottom: 20px;
}

label, input {
  margin: 5px;
}
.tables-container {
  display: flex; /* 启用Flexbox布局 */
  justify-content: space-between; /* 使子元素分布在容器的两端 */
  width: 100%; /* 确保容器占满整个父级宽度 */
}

.table-container {
  width: 48%; /* 每个表格占据容器宽度的大约一半 */
  display: flex;
  flex-direction: column;
}






th, td {
  border: 1px solid #ccc; /* 给表格单元格添加边框 */
  text-align: left; /* 文本左对齐 */
  padding: 8px; /* 添加一些内边距 */
}

.order-header {
  background-color: #f9f9f9; /* 给表头添加背景色 */
}

.select-container {
  display: flex;
  justify-content: flex-start; /* 修改后的样式 */
  gap: 20px;
}
</style>
