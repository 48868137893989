<template>
  <div id="app">
    <router-view></router-view> <!-- 路由视图 -->
  </div>
</template>

<script>
export default {
  // 根组件的脚本部分
  name: 'App'
}
</script>

<style>
/* 全局样式 */
</style>
