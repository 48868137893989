import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginComponent.vue';
import OrderQuery from '../views/OrderQuery.vue';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/order-query',
        name: 'OrderQuery',
        component: OrderQuery
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
